.wrapper {
    @apply col-span-6 sm:col-span-3;
}

.wrapper-xs {
    @apply sm:col-span-1;
}

.wrapper-s {
    @apply sm:col-span-2;
}

.wrapper-l {
    @apply sm:col-span-4;
}

.wrapper-xl {
    @apply sm:col-span-6;
}

.wrapper-full {
    @apply col-span-full;
}

.label {
    @apply block pb-2 text-base font-semibold text-text-primary;
}

.innerControl {
    @apply relative flex flex-grow flex-col justify-end;
}

.innerControl :is(input, textarea, select) {
    @apply block w-full rounded-full border-none px-4 py-5 font-medium leading-6 text-text-primary outline-none ring-1 ring-inset ring-gray-10 transition-all placeholder:text-text-secondary hover:ring-primary-hover focus:ring-inset focus:ring-primary-base;
}

.innerControlError :is(input, textarea, select) {
    @apply block w-full  text-error ring-1 ring-inset ring-error hover:ring-error focus:ring-error;
}

.messageInfo {
    @apply mb-0 mt-2 inline-block text-base text-text-secondary;
}

.messageError {
    @apply mt-2 text-base text-text-secondary;
}
