@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Gilroy;
    font-weight: 400;
    font-display: swap;
    src:
        url('../../shared/assets/fonts/Gilroy/Gilroy-Regular.woff2')
            format('woff2'),
        url('../../shared/assets/fonts/Gilroy/Gilroy-Regular.woff')
            format('woff');
}

@font-face {
    font-family: Gilroy;
    font-weight: 500;
    font-display: swap;
    src:
        url('../../shared/assets/fonts/Gilroy/Gilroy-Medium.woff2')
            format('woff2'),
        url('../../shared/assets/fonts/Gilroy/Gilroy-Medium.woff')
            format('woff');
}

@font-face {
    font-family: Gilroy;
    font-weight: 600;
    font-display: swap;
    src:
        url('../../shared/assets/fonts/Gilroy/Gilroy-Semibold.woff2')
            format('woff2'),
        url('../../shared/assets/fonts/Gilroy/Gilroy-Semibold.woff')
            format('woff');
}
@layer components {
    .custom-scroll::-webkit-scrollbar {
        @apply w-3;
    }
    .custom-scroll::-webkit-scrollbar:horizontal {
        @apply h-2;
    }
    .custom-scroll::-webkit-scrollbar-track {
        @apply rounded-full bg-stroke-100;
    }
    .custom-scroll::-webkit-scrollbar-thumb {
        @apply rounded-full border-2 border-solid border-stroke-100 bg-stroke-500 transition-colors hover:bg-text-secondary;
    }
}
html {
    font:
        400 1rem Gilroy,
        sans-serif;
}
p {
    @apply mb-6 text-base font-normal text-text-secondary lg:text-xl;
}
p + p {
    @apply mb-0;
}
ol,
ul {
    @apply mb-6 list-inside list-decimal text-text-secondary;
}
ul {
    @apply list-disc leading-5 marker:text-xs;
}
:is(ol, ul) li {
    @apply mb-2 text-xl;
}
