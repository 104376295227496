.button {
    @apply rounded-full transition-colors;
}
.button.primary {
    @apply bg-primary-base text-white hover:bg-primary-hover active:bg-primary-active disabled:bg-primary-disabled;
}
.button.secondary {
    @apply rounded-full bg-secondary-base text-white hover:bg-secondary-hover active:bg-secondary-active disabled:bg-secondary-disabled;
}
.button.outline {
    @apply border border-secondary-base bg-transparent hover:border-secondary-hover active:border-outline-active active:bg-outline-active active:text-white disabled:border-secondary-disabled disabled:bg-transparent disabled:text-text-secondary;
}
.button.white {
    @apply bg-white text-text-primary hover:text-primary-base active:bg-primary-100 active:text-primary-base disabled:opacity-20;
}
.button.link {
    @apply !inline-block !p-0 text-primary-base hover:text-primary-hover active:text-primary-active;
}
.button.sm {
    @apply px-3 py-2 text-sm font-normal leading-4;
}
.button.md {
    @apply p-4 text-base font-medium leading-none;
}
.button.lg {
    @apply px-10 py-5 text-sm font-semibold leading-6;
}
.button.icon {
    @apply inline-flex items-center gap-x-1.5 py-3.5;
}
.button.loading {
    @apply inline-flex items-center justify-center gap-x-1.5;
}
.secondary.loading > div {
    @apply border-white;
}
.button.loading > div + svg {
    @apply hidden;
}
.button.square {
    @apply flex h-12 w-12 items-center justify-center p-0;
}
